import { graphql } from 'gatsby';
import React from 'react';
import { FaFacebookSquare, FaTwitter, FaInstagram } from 'react-icons/fa';
import tw from 'tailwind.macro';
import AddToCalendar from '@culturehq/add-to-calendar';
import Grid from '../components/grid';
import Layout from '../components/layout';
import LeadText from '../components/leadText';
import Masthead from '../components/masthead';
import Panel from '../components/panel';
import SafeLink from '../components/safeLink';
import Section from '../components/section';
import SEO from '../components/seo';
import SharingButtons from '../components/sharingButtons';

export default function PostTemplate({
  location,
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html, excerpt } = markdownRemark;
  const parentTitle = 'The Beyond Just Us Blog';
  const pageTitle = frontmatter.title;

  return (
    <Layout
      location={location}
      // parentTitle={`Ideas > ${categories[frontmatter.category].name}`}
      pageTitle={pageTitle}
    >
      <SEO
        title={pageTitle}
        description={excerpt}
        // image={frontmatter.thumbnail.childImageSharp.fluid.src}
      />
      <Masthead
        parentTitle={parentTitle}
        pageTitle={pageTitle}
        backgroundImage={data.hero.childImageSharp.fluid}
        styling={[tw`bg-maroon`, `min-height:30vh;`]}
      />
      <Section>
        <Grid>
          <div className="col-span-2">
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: html }}
              css={{
                'a.button': tw`bg-maroon px-4 py-2 text-sm inline-block text-600 rounded-full text-xs text-white uppercase font-bold tracking-wider border border-transparent hover:no-underline hover:bg-dark-gray`,
                a: tw`text-maroon font-bold hover:underline`,
                blockquote: tw`my-10 pl-5 text-xl italic border-l-4 border-maroon`,
                '.gatsby-resp-image-wrapper': { 'margin-left': '0 !important' },
              }}
            />
            {frontmatter.eventEndDate &&
            frontmatter.calendarDescription &&
            frontmatter.eventEndDate < new Date() ? (
              <div css={[`a { margin-right:10px; color:#60223b !important; }`]}>
                <AddToCalendar
                  event={{
                    name: frontmatter.title,
                    startsAt: frontmatter.eventDate,
                    endsAt: frontmatter.eventEndDate,
                    location: frontmatter.calendarLocation,
                    details: `${frontmatter.calendarDescription}`,
                  }}
                >
                  <span css={tw`text-maroon font-bold align-top`}>
                    Add to my calendar &rarr;
                  </span>
                </AddToCalendar>
              </div>
            ) : (
              ''
            )}

            <hr css={tw`my-5`} />
            <p css={tw`uppercase tracking-wider font-bold`}>Share this post:</p>
            <SharingButtons
              title={pageTitle}
              url={`https://socialjustice.sun.ac.za/blog${frontmatter.permalink}`}
            />
          </div>
          <div css={tw`mt-5 md:mt-0`}>
            <Panel>
              <LeadText>
                <strong>By {frontmatter.author}</strong> <br />
                Published {frontmatter.date}
              </LeadText>
              <hr css={tw`my-5`} />
              <p css={tw`uppercase tracking-wider font-bold`}>
                Share this post:
              </p>
              <SharingButtons
                title={pageTitle}
                url={`https://socialjustice.sun.ac.za/blog${frontmatter.permalink}`}
              />
            </Panel>
            <Panel css={tw`mt-10`}>
              <h3>Connect with us!</h3>
              <p>
                <SafeLink
                  to="https://www.facebook.com/Social-Justice-M-Plan-343817256222320/"
                  css={tw`text-maroon text-lg`}
                >
                  <FaFacebookSquare
                    css={tw`inline mr-2 hover:text-dark-gray text-3xl align-middle`}
                  />
                  Social Justice M-Plan
                </SafeLink>
              </p>
              <p>
                <SafeLink
                  to="https://twitter.com/CFSJ_MPlan"
                  css={tw`text-maroon text-lg`}
                >
                  <FaTwitter
                    css={tw`inline mr-2 hover:text-dark-gray text-3xl align-middle`}
                  />
                  @CFSJ_MPlan
                </SafeLink>
              </p>
              <p>
                <SafeLink
                  to="https://www.instagram.com/socialjusticechairsu/"
                  css={tw`text-maroon text-lg`}
                >
                  <FaInstagram
                    css={tw`inline mr-2 hover:text-dark-gray text-3xl align-middle`}
                  />
                  @socialjusticechairsu
                </SafeLink>
              </p>
            </Panel>
          </div>
        </Grid>
      </Section>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { permalink: { eq: $path } }) {
      html
      excerpt
      frontmatter {
        permalink
        title
        date(formatString: "D MMMM YYYY")
        author
        eventDate
        eventEndDate
        calendarLocation
        calendarDescription
      }
    }
    hero: file(relativePath: { eq: "home-hero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
